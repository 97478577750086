import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"

import { Box,
  Heading,
  Select,
  List,
  ListItem,
  Divider,
  HStack,
  Text,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  IconButton,
  SimpleGrid,
  Center
} from "@chakra-ui/react"
import { 
  ChevronLeftIcon,
  ChevronRightIcon, } from '@chakra-ui/icons'
import { Link as GatsbyLink } from 'gatsby'
import Layout from '../components/Layout.js';
import Seo from '../components/seo_metadata.js';

const CalenderEvents = ({ data }) => {

  console.log(data);

  var dt_p_1 = new Date();
  var dt0 = new Date();
  var dt_m_1 = new Date();
  var dt_m_2 = new Date();
  var dt_m_3 = new Date();
  var dt_m_4 = new Date();
  var dt_m_5 = new Date();
  var dt_m_6 = new Date();

  dt_p_1.setMonth(dt_p_1.getMonth() + 1);
  dt_m_1.setMonth(dt_m_1.getMonth() - 1);
  dt_m_2.setMonth(dt_m_2.getMonth() - 2);
  dt_m_3.setMonth(dt_m_3.getMonth() - 3);
  dt_m_4.setMonth(dt_m_4.getMonth() - 4);
  dt_m_5.setMonth(dt_m_5.getMonth() - 5);
  dt_m_6.setMonth(dt_m_6.getMonth() - 6);

  // var monthArray = [dt_p_1, dt0, dt_m_1, dt_m_2, dt_m_3, dt_m_4, dt_m_5, dt_m_6];
  var monthArray = [dt_m_6, dt_m_5, dt_m_4, dt_m_3, dt_m_2, dt_m_1, dt0, dt_p_1];
  // let releseDatesYear = [];
  // data.forEach((_data)=>{
  //   var sliceYear = (_data).slice(0,4);
  //   if(releseDatesYear.indexOf(sliceYear) == -1){
  //     releseDatesYear.push(sliceYear);
  //   }
  // })
  // releseDatesYear.sort();

  function compare( a, b ) {
    if ( a.day < b.day ){
      return -1;
    }
    if ( a.day > b.day ){
      return 1;
    }
    return 0;
  }

  let releseDates = [];
  monthArray.forEach((_month) =>{
    var monthcontents = [];
    data.forEach((_data)=>{
      const hollNewsDate01 = _data.hollACF.hollNewsDate01.split("/");
      var dataYear =  parseInt(hollNewsDate01[2]);
      var dataMonth = parseInt(hollNewsDate01[1]);
      _data.day = parseInt(hollNewsDate01[0]);

      var hoplldate = new Date(hollNewsDate01[2],hollNewsDate01[1]-1,hollNewsDate01[0]);

      var dayOfWeek = hoplldate.getDay() ;	// 曜日(数値)
      var dayOfWeekStr = [ "(日)", "(月)", "(火)", "(水)", "(木)", "(金)", "(土)" ][dayOfWeek] ;	// 曜日(日本語表記)
      _data.dayOfWeekStr = dayOfWeekStr;

      if(_month.getFullYear() == dataYear && _month.getMonth()+1 == dataMonth){
        monthcontents.push(_data);
      }
    })
    monthcontents.sort(compare);

    releseDates.push({
      year:_month.getFullYear(), 
      month:_month.getMonth()+1, 
      contents:monthcontents
    });
  })

  const [tabIndex, setTabIndex] = useState(releseDates.length - 2);
  const handleTabsChange = (index) => {
    setTabIndex(index);
  }

  const [LeftDisable, setLeftDisable] = useState(true)
  const [RightDisable, setRightDisable] = useState(true)

  const handleLeftChange = (event) => {
    var index = tabIndex-1;
    if(index <= 0) {
      index = 0;
      setLeftDisable(false);
    }
    if(index != 0) setLeftDisable(true);
    if(index != releseDates.length - 1) setRightDisable(true);
    setTabIndex(index)
  }

  const handleRightChange = (event) => {
    var index = tabIndex+1;
    if(index >= releseDates.length - 1) {
      index = releseDates.length - 1;
      setRightDisable(false);
    }
    if(index != 0) setLeftDisable(true);
    if(index != releseDates.length - 1) setRightDisable(true);
    setTabIndex(index)
  }

  

  // console.log(releseDates);
  // var monthArray = 
  //   ["01","02","03",
  //   "04","05","06",
  //   "07","08","09",
  //   "10","11","12"];

  return (
    <>
    
    <Tabs index={tabIndex} onChange={handleTabsChange} border={"1px solid gray"}>
      <HStack justifyContent="space-between" borderBottom={"1px solid gray"}>
        <IconButton icon={<ChevronLeftIcon />} isDisabled={!LeftDisable} onClick={handleLeftChange}/>
        <Box>{releseDates[tabIndex].year}年{releseDates[tabIndex].month}月</Box>
        <IconButton icon={<ChevronRightIcon />} isDisabled={!RightDisable} onClick={handleRightChange}/>
      </HStack>
      <TabList display="none">
        {releseDates.map((tab, index) => (
          <Tab key={index}>{tab.year}{tab.month}</Tab>
        ))}
      </TabList>
      <TabPanels>
        {releseDates.map((tab, index) => (
          <TabPanel p={4} key={index}>
            { tab.contents.length == 0 ?
              <Box>特になし</Box>
              :
              tab.contents.map((content,index)=>(
                <>
                <HStack columns={3} m={2}>
                  <Box mr={3}>
                    <Text>{content.day}日{content.dayOfWeekStr}</Text>
                  </Box>
                  <Box>
                    <HStack>
                      <Box>
                        <Text>{content.area}</Text>
                      </Box>
                      <Box>
                        <Text>[{content.pref}]</Text>
                      </Box>
                    </HStack>
                    <Box>
                      <GatsbyLink to={content.uri}>
                        <Text fontWeight="semibold">{content.title}</Text>
                      </GatsbyLink>
                    </Box>
                  </Box>
                </HStack>
                <Box p={1} >
                  <Divider borderColor="gray"/>
                </Box>
                </>
                
              ))
            }
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
    </>
  )
}

const Event = () => {

  let graphqlResult = useStaticQuery(graphql`
    query {
      allWpPost(
        sort: {fields: date, order: DESC}
        filter: {categories: {nodes: {elemMatch: {slug: {eq: "hdata"}}}}}
      ) {
        nodes {
          uri
          title
          id
          date(formatString: "YYYY.MM.DD")
          hollACF {
            hollNewsDate01
            hollAddress
          }
        }
      }
    }
  `)

  const allPosts = graphqlResult.allWpPost.nodes.map( node => (node));

  const [areaIndex, setAreaIndex] = useState(0);
  const selectChange = (event) => {
    setAreaIndex(parseInt(event.target.value))
  }

  const pref = ['北海道','青森','岩手','宮城','秋田','山形','福島','東京','神奈川','茨城','栃木','群馬','埼玉','千葉','愛知','静岡','岐阜','長野','山梨','福井','石川','富山','新潟','三重','大阪','京都','兵庫','滋賀','奈良','和歌山','鳥取','島根','岡山','広島','山口','徳島','香川','愛媛','高知','福岡','佐賀','長崎','熊本','大分','宮崎','鹿児島','沖縄'];

  var areadatas = [9];
  areadatas[0] = []; //全国
  areadatas[1] = [];
  areadatas[2] = [];
  areadatas[3] = [];
  areadatas[4] = [];
  areadatas[5] = [];
  areadatas[6] = [];
  areadatas[7] = [];
  areadatas[8] = [];

  allPosts.forEach( (post) => {
    if(post.hollACF.hollNewsDate01){
      const hollNewsDate01 = post.hollACF.hollNewsDate01.split("/");
      if(hollNewsDate01){
        var dt = new Date();
        var dt6 = dt.setMonth(dt.getMonth() - 6);
        var hoplldate = new Date(hollNewsDate01[2],hollNewsDate01[1]-1,hollNewsDate01[0]);
        if(dt6 < hoplldate){
          

          //先頭３文字を切り出す
          var prefsub = post.hollACF.hollAddress.substr(0,3);
          var index = pref.indexOf(prefsub);
          post.pref = pref[index];
          if(index == -1){
            //先頭2文字を切り出す
            prefsub = post.hollACF.hollAddress.substr(0,2);
            index = pref.indexOf(prefsub);
            post.pref = pref[index];
          }

          if(index != -1){
            if(index == 0){post.area = "北海道地方"; areadatas[1].push(post);}
            if(index >= 1 && index <= 6){post.area = "東北地方";areadatas[2].push(post);}
            if(index >= 7 && index <= 13){post.area = "関東地方";areadatas[3].push(post);}
            if(index >= 14 && index <= 22){post.area = "中部地方";areadatas[4].push(post);}
            if(index >= 23 && index <= 29){post.area = "関西地方";areadatas[5].push(post);}
            if(index >= 30 && index <= 34){post.area = "中国地方";areadatas[6].push(post);}
            if(index >= 35 && index <= 38){post.area = "四国地方";areadatas[7].push(post);}
            if(index >= 39 && index <= 46){post.area = "九州・沖縄地方";areadatas[8].push(post);}
          }

          areadatas[0].push(post);
        }
      }
    }
  });


  return (
    <Layout>
       <Seo 
         title={"パチンカーズネットイベント情報"} 
         description={"パチンコ・スロット店のグランドオープン情報等をまとめています"}
      />
      <Box bgGradient="repeating-linear(135deg, transparent, transparent 1px, rgba(216, 228, 227, 1) 1px, rgba(216, 228, 227, 1) 2px)">
        <Center p={"2vw"}>
          <Heading as="h2" fontSize="4vw" isTruncated>
            イベント情報
          </Heading>
        </Center>
      </Box>
      <Box m={2}>
        <Box p={"2vw"}>
          <Heading as="h3" fontSize="2.5vw" isTruncated>
            新着イベント情報
          </Heading>
        </Box>
        <List p={"3vw"}>

          {
            allPosts.slice(0,10).map(node => {
              const title = node.title;
              const date = node.date;

              return (
                <>
                <Box p={2} >
                  <Divider borderColor="gray"/>
                </Box>
                <ListItem m={2} key={node.id+"n"} p={1}>
                  <HStack justifyContent="space-between">
                    <Text align="right" fontWeight="bold" textTransform="uppercase" fontSize="sm" letterSpacing="wide" color="teal.600" >
                      {date}
                    </Text>
                  </HStack>
                  <GatsbyLink to={node.uri} >
                    <Text fontWeight="bold">{title}</Text>
                  </GatsbyLink>
                </ListItem>
                </>
              )
            })
          }
        </List>

        <Box p={"2vw"}>
          <Heading as="h3" fontSize="2.5vw" isTruncated>
            イベントカレンダー
          </Heading>
        </Box>

        <Select placeholder="地域選択" onChange={selectChange}>
          <option value="0">全国</option>
          <option value="1">北海道地方</option>
          <option value="2">東北地方</option>
          <option value="3">関東地方</option>
          <option value="4">中部地方</option>
          <option value="5">関西地方</option>
          <option value="6">中国地方</option>
          <option value="7">四国地方</option>
          <option value="8">九州・沖縄地方</option>
        </Select>

        <CalenderEvents data={areadatas[areaIndex]} />
      </Box>
      
    </Layout>
  )
}

export default Event